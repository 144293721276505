import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import hanliepotgieter from '../assets/images/hanliepotgieter.jpg'
import ProfilePic from '../components/ProfilePic'

class Homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render() {
    const siteTitle = 'hanlie claasen interiors'

    return (
      <Layout ishome={true}>
        <Helmet title={siteTitle}/>
        <section id="aboutus" className="main style1">
          <div className="grid-wrapper">

            <div className="col-12">
              <header className="major">
                <h2>about us</h2>

              </header>
              <blockquote><h3>"Have nothing in your house (spaces) that you do not know to be useful or believe to be
                beautiful."</h3></blockquote>
              <h3>~ William Morris</h3>
            </div>
          </div>
        </section>
        <section id="team" className="main style1">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>the team</h2>
              </header>
            </div>
            <div className="col-12">
              <ProfilePic picture={hanliepotgieter} size={{ mobile: '60%', computer: '50%' }}/>
              <header className="minor align-center">
                <h2>Hanlie Claasen</h2>
              </header>
              <p>since graduating in Home Economics she designed her career by:
                <ul>
                  <li>Working for two decorators, to gain work experience</li>
                  <li>After living abroad, she started her decorating business in 2000</li>
                  <li>Being passionate about workmanship she initiated a curtain workshop</li>
                  <li>Lectured the module Interior Production to UP’s Consumer Science students</li>
                  <li>Exhibited at 2005 Rooms on View décor show</li>
                  <li>Broadcast 4 episodes on MNet’s Dekor show featuring Hanlie’s projects</li>
                  <li>Gradually the focus of hci moved to a design company with more project management involvement</li>
                </ul>
              </p>
            </div>
          </div>
        </section>


        <section id="projects" className="main style1 special">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>projects</h2>
              </header>
              <p>We’ll work with you from the initial briefing and design concept stage
                through to implementation and installation, ensuring that deadlines are met
                and budgets adhered to. At the start of the process, you’ll receive a
                detailed, line by line estimate based on your brief and budget.</p>
            </div>

            {/*<div className="col-6">*/}
            {/*  <span className="image fit"><img src={pic03} alt=""/></span>*/}
            {/*  <h3>Residential</h3>*/}
            {/*  <p>We pride ourselves in not having a ‘signature’ we don’t let fashions or genres predict the*/}
            {/*    spaces we design but, every project is unique to the customer’s needs. It responds to*/}
            {/*    the senses, needs, aspirations ergonomics and connotations of the place. The created*/}
            {/*    spaces truly reflect and perfectly fit the people who use it.*/}
            {/*    <br/><br/>*/}
            {/*    ‘Homes should be the backdrop to life, full of memories and things that have been*/}
            {/*    collected.’*/}
            {/*    <br/><br/>*/}
            {/*    Above all, we love designing places that work beautifully for the long term,*/}
            {/*    and feel right for place.</p>*/}
            {/*  <ul className="actions">*/}
            {/*    /!*<li><a href="#" className="button">More</a></li>*!/*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="col-6">*/}
            {/*  <span className="image fit"><img src={pic04} alt=""/></span>*/}
            {/*  <h3>Corporate</h3>*/}
            {/*  <p>We take the job of listening, understanding and looking after each client very seriously*/}
            {/*    and see their practical requirements as being every bit as important as questions of*/}
            {/*    style.</p>*/}
            {/*  <ul className="actions">*/}
            {/*    /!*<li><a href="#" className="button">More</a></li>*!/*/}
            {/*  </ul>*/}
            {/*</div>*/}

          </div>
        </section>

        {/*<section id="social" className="main style2 special">*/}
        {/*  <div className="container">*/}
        {/*    <header className="major">*/}
        {/*      <h2>Ipsum feugiat consequat?</h2>*/}
        {/*    </header>*/}
        {/*    <p>Sed lacus nascetur ac ante amet sapien.</p>*/}
        {/*    <ul className="actions uniform">*/}
        {/*      <li><a href="#" className="button special">Sign Up</a></li>*/}
        {/*      <li><a href="#" className="button">Learn More</a></li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</section>*/}
      </Layout>
    )
  }
}

export default Homepage
