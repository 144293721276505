import React from 'react'

class ProfilePic extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
      ismobile: false,
    }
  }

  componentDidMount() {
    this.setState({ ismobile: (window.innerWidth <= 1000) })
  }
 
  render() {
    return <>
    <span className="image fit center">
  <img role="presentation" 
      //   onMouseEnter={() => this.setState({ hover: true })}
      //  onMouseLeave={() => this.setState({ hover: false })}
      //  onTouchStart={() => this.setState({ hover: true })}
      //  onTouchEnd={() => this.setState({ hover: false })}
       onContextMenu={(e)=> e.preventDefault()}
       draggable={false}
       style={{
         display: 'block',
         'margin-left': 'auto',
         'margin-right': 'auto',
         width: this.state.ismobile ? this.props.size.mobile : this.props.size.computer,
         borderRadius: '50%',
        //  '-webkit-filter': !(this.state.hover) ? 'grayscale(100%)' : '',
        //  '-moz-filter': !(this.state.hover) ? 'grayscale(100%)' : '',
         'transition': '0.7s ease',
       }
       }

       src={this.props.picture} alt=""/></span>
    </>
  }
}

export default ProfilePic